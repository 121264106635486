import Eatery from 'Class/Eatery';
import Parser from 'Class/Parser';
import Spinner from 'Components/Feedback/Spinner';

import Box from 'Components/Layout/Box';
import Full from 'Components/Layout/Full';
import LocalLogo from 'Components/Layout/LocalLogo';
import OpeningHours from 'Components/Layout/OpeningHours';
import OpenStatus from 'Components/Layout/OpenStatus';
import Segment from 'Components/Layout/Segment';
import ThreeColumns from 'Components/Layout/ThreeColumns';
import Button from 'Components/UI/Button';
import Link from 'Components/UI/Link';
import Links from 'Components/UI/Links';
import Modules from 'Components/View/Modules';
import ModuleInstagram from 'Components/View/Modules/Instagram';
import ModuleNews from 'Components/View/Modules/News';
import React from 'react';
import './eatery.scss';

class TemplateEatery extends React.Component {
    ColumnLeft = () => {
        const {content} = this.props;
        const {
            address,
            deviatingHours,
            email,
            location,
            openingHours,
            phone,
        } = content || {};
        const Address = address.split('\n');
        const AddressItems = [];
        Address.forEach((row, index) => {
            if (!location || index) {
                AddressItems.push(<div key={index}>{row}</div>);
            } else {
                AddressItems.push(<Link
                    className="EateryNavigationContactLink"
                    key={index}
                    href={`https://www.google.com/maps/place/?q=place_id:${location.id}`}
                >{row}</Link>);
            }
        });
        return (
            <div className="TemplateEateryLeft">
                {(openingHours && openingHours.length) ? <Box color="Beige">
                    <h6>Öppettider</h6>
                    <OpenStatus
                        days={openingHours}
                        deviations={deviatingHours}
                    />
                    <OpeningHours
                        days={openingHours}
                        deviations={deviatingHours}
                    />
                </Box> : ''}
                <Box color="Beige">
                    <h6>Kontakt</h6>
                    <div className="TemplateEateryLeftContact">
                        {AddressItems}
                    </div>
                    <div className="TemplateEateryLeftContact">
                        {email
                            ? <Link href={`mailto:${email}`}>{email}</Link>
                            : ''}
                        {phone
                            ? <Link href={`tel:${phone}`}>{phone}</Link>
                            : ''}
                    </div>
                </Box>
            </div>
        );
    };

    ColumnRight = () => {
        const {uri} = this.props;
        const Menues = Eatery.Menues(uri);
        const Items = [];
        for (let slug in Menues) {
            const {content: menuContent, title: menuTitle} = Menues[slug] || {};
            const {pdf} = menuContent || {};

            if (!menuContent) {
                continue;
            }

            if (pdf) {
                Items.push({
                    label: menuTitle,
                    target: '_blank',
                    type: 'link',
                    url: pdf,
                });
            } else {
                Items.push({
                    label: menuTitle,
                    to: `${uri}/${slug}`,
                });
            }
        }
        return (
            <div className="TemplateEateryRight">
                <Links
                    color="Beige"
                    items={Items}
                    vertical={true}
                />
            </div>
        );
    };

    render() {
        const {content, title, uri} = this.props;
        const {
            about,
            altLocation,
            bookingUrl,
            bookingUrlConf,
            contents,
            deliveryBusiness,
            deliveryPrivate,
            external,
            extraButtons,
            functions,
            image,
            imagePositionH,
            imagePositionV,
            textColor,
            xmasMode,
            numberOfNuggets,
        } = content || {};

        if (external) {
            window.location.href = external;
            return (
                <Full top={true}>
                    <Spinner />
                </Full>
            );
        }
        const DeliveryLinks = Eatery.Option('deliveryLinks', []);
        const [DeliveryPrivate, DeliveryBusiness] = DeliveryLinks;
        const Buttons = [];
        const Functions = functions ? Object.keys(functions) : [];
        const HasCatering = Functions.indexOf('catering') >= 0;
        const HasConference = Functions.indexOf('conference') >= 0;
        const HasEvent = Functions.indexOf('event') >= 0;
        const LocationUri = altLocation ? altLocation : uri;
        if (xmasMode && bookingUrl) {
            Buttons.push(
                <Button
                    color="xmas"
                    href={bookingUrl}
                    label="Boka julbord"
                    key="xmasbooking"
                />,
            );
        } else if (bookingUrl) {
            Buttons.push(
                <Button
                    color={textColor === 'white' ? 'White' : 'Black'}
                    href={bookingUrl}
                    label="Boka bord"
                    key="booking"
                />,
            );
        }
        if (bookingUrlConf) {
            Buttons.push(
                <Button
                    color={textColor === 'white' ? 'White' : 'Black'}
                    href={bookingUrlConf}
                    label="Boka konferens"
                    key="booking"
                />,
            );
        }
        if (extraButtons && Array.isArray(extraButtons)) {
            extraButtons.forEach((button, index) => {
                const {action, color, hollow, label, page, type, url} = button;
                switch (type) {
                    case 'action':
                        Buttons.push(
                            <Button
                                color={color}
                                hollow={hollow}
                                key={index}
                                label={label}
                                onClick={() => Eatery.Action(action)}
                            />,
                        );
                        break;
                    case 'link':
                        if (url[0] === '/') {
                            Buttons.push(
                                <Button
                                    color={color}
                                    hollow={hollow}
                                    key={index}
                                    label={label}
                                    to={url}
                                />,
                            );
                        } else {
                            Buttons.push(
                                <Button
                                    color={color}
                                    hollow={hollow}
                                    href={url}
                                    key={index}
                                    label={label}
                                />,
                            );
                        }
                        break;
                    default:
                        Buttons.push(
                            <Button
                                color={color}
                                hollow={hollow}
                                key={index}
                                label={label || Eatery.GetTitle(page)}
                                to={page}
                            />,
                        );
                }
            });
        }

        return (
            <div className="TemplateEatery">
                <Full
                    backgroundColor="Black"
                    backgroundImage={image}
                    backgroundPositionH={imagePositionH}
                    backgroundPositionV={imagePositionV}
                    className="TemplateEateryTop"
                    textColor={textColor}
                    top={true}
                >
                    <LocalLogo
                        heading={1}
                        name={title}
                        rotate={true}
                        uri={uri}
                    />
                    {Buttons.length ? (
                        <div className="SegmentButtons Buttons TemplateEateryButtons">
                            {Buttons}
                        </div>
                    ) : ''}
                </Full>
                <Segment>
                    <ThreeColumns
                        left={this.ColumnLeft()}
                        right={this.ColumnRight()}
                    >
                        {Parser.Parse(
                            about,
                            'div',
                            null,
                            {className: 'TemplateEateryAbout'},
                        )}

                        <div className="eatery-buttons">

                            {HasConference || HasEvent ? <Button
                                color="Black"
                                to={`/anlaggningar${LocationUri}`}
                                label="Konferens"
                            /> : ''}
                            {HasConference || HasEvent ? <Button
                                color="Black"
                                to={`/anlaggningar${LocationUri}#event`}
                                label="Fest"
                            /> : ''}
                            {HasCatering ? <Button
                                hollow={true}
                                to="/catering"
                                label="Catering"
                            /> : ''}

                        </div>

                        {(deliveryBusiness || deliveryPrivate) ? (
                            <div className="SegmentButtons Buttons">
                                {(DeliveryBusiness && deliveryBusiness) ?
                                    <Button
                                        color="Black"
                                        href={DeliveryBusiness.url}
                                        label="Take-away & utkörning"
                                        subLabel="Företag"
                                    /> : ''}
                                {(DeliveryPrivate && deliveryPrivate) ? <Button
                                    color="Black"
                                    href={DeliveryPrivate.url}
                                    label="Take-away & utkörning"
                                    subLabel="Privat"
                                /> : ''}
                            </div>
                        ) : ''}
                    </ThreeColumns>
                </Segment>
                <Modules modules={contents} startIndex={2} />
                <ModuleNews
                    backgroundColor="Black"
                    eatery={uri}
                    index={1}
                    postsLimit={numberOfNuggets || 8}
                />
                <ModuleInstagram
                    backgroundColor="Beige"
                    eatery={uri}
                    index={1}
                    title={`Följ Eatery ${title} på Instagram`}
                />
            </div>
        );
    }
}

TemplateEatery.defaultProps =
    {
        content: {},
        title: '',
        uri: '',
    };

export default TemplateEatery;
